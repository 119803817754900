import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export default function SEO({ title, description, pagename }) {

    const data = useStaticQuery(query)
    const metadata = data.site.siteMetadata
    const { siteUrl } = metadata
    const logoURL = `${siteUrl}${data.logo.publicURL}`
    const url = `${siteUrl}/${pagename}`

    return (
        <Helmet title={title}>
            <html lang="es" />
            <meta charSet="utf-8" />
            
            <meta name="description" content={description} />
            <meta name="image" content={logoURL} />
            
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={logoURL} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={logoURL} />
        </Helmet>
    )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
    logo: file(relativePath: {eq: "logo.png"}) {
		publicURL
    }
  }
`
