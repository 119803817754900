import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Link } from "gatsby"

const Wrapper = styled.header({
    display: "flex",
    flexFlow: "row wrap",
    flexBasis: "35em",
    padding: "1em",
    "@media(max-width: 1116px)": {
        justifyContent: "center",
        flexBasis: "100%",
    }
})

const TitleWrapper = styled.div({
    marginLeft: "3em",
    "@media(max-width: 510px)": {
        marginLeft: 0,
    }
})

const Title = styled.h1({
    color: "#000",
    fontSize: "2em",
    fontWeight: "600",
    letterSpacing: "0.08em",
    "@media(max-width: 550px)": {
        textAlign: "center"
    }
})

const Subtitle = styled.h2({
    color: "#000",
    fontSize: "1.4em",
    fontWeight: "400",
    "@media(max-width: 550px)": {
        textAlign: "center"
    }
})

const LogoStyles = css({
    "&:hover": {
        width: "100%",
        height: "100%",
    },
    width: "97%",
    height: "97%",
    margin: "0.6em",
    transition: "width 200ms, height 200ms",
    "@media(max-width: 510px)": {
        marginLeft: 0,
    }
})

export default function Header() {
    return (
        <Wrapper>
            <Link to="/">
                <StaticImage src="../assets/images/logo.png" alt="Logo de Mayresur" css={LogoStyles} />
            </Link>
            <TitleWrapper>
                <Title>Mayresur</Title>
                <Subtitle>Maquinaria y repuestos del sur</Subtitle>
            </TitleWrapper>
        </Wrapper>
    )
}
