import * as React from "react"
import Navbar from "./NavBar"
import Header from "./Header"
import "@fontsource/jost/400.css"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import WhatsAppIcon from "./WhatsAppIcon"
import Footer from "./Footer"

const Wrapper = styled(BackgroundImage)({
    fontFamily: "jost",
    minHeight: "100vh",
    width: "100%",
    backgroundAttachment: "fixed",
    backgroundPosition: "left center"
})

const Main = styled.div({
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    marginBottom: "1.8em",
})

export default function MainContainer({ children }) {

    const data = useStaticQuery(GetBackgroundImage)
    const bgImage = convertToBgImage(getImage(data.bgImage))

    return (
        <Wrapper {...bgImage} preserveStackingContext>
            <Main>
                <Header />
                <Navbar />
            </Main>
            {children}
            <Footer />
            <WhatsAppIcon />
        </Wrapper>
    )
}

const GetBackgroundImage = graphql`
    query {
        bgImage: file(relativePath: { eq: "bg.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 2500, height: 1200)
            }
        }
    }
`
