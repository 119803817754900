import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Nav = styled.nav({
    display: "flex",
    flexFlow: "row wrap",
    flexBasis: "30em",
    padding: "1.4em",
    "@media(max-width: 1116px)": {
        justifyContent: "center",
        flexBasis: "100%"
    }
})

const NavLink = styled(Link)({
    textTransform: "uppercase",
    textDecoration: "none",
    color: "#000",
    height: "1.2em",
    paddingBottom: "0.8em",
    width: "7em",
    display: "inline",
    textAlign: "center",
    "&::after": {
        display: "block",
        margin: "0 auto",
        marginTop: "0.6em",
        backgroundColor: "#FBC02D",
        content: "''",
        height: "4px",
        width: 0,
        transition: "width 400ms",
        textShadow: "2px 2px #000",
    },
    transition: "color 400ms",
    "@media(max-width: 380px)": {
        textAlign: "left",
        marginLeft: "1em"
    }
}, ({ underline }) => ({
    "&:hover": {
        color: "#FBC02D",
        "&::after": {
            width: underline,
        },
    },
}))

export default function Navbar() {
    return (
        <Nav>
            <NavLink to="/" underline="3.5em">Inicio</NavLink>
            <NavLink to="/products" underline="6em">Productos</NavLink>
            <NavLink to="/sectors" underline="5.5em">Sectores</NavLink>
            <NavLink to="/contact" underline="5.5em">Contacto</NavLink>
        </Nav>
    )
}
