export default color => ({
    "&:before": {
        content: "''",
        position: "relative",
        left: "-0.8em",
        top: "1.12em",
        display: "block",
        borderRight: `3px solid ${color}`,
        borderBottom: `3px solid ${color}`,
        width: "0.35em",
        height: "0.35em",
        transform: "translate(-50%, -50%) rotate(-45deg)",
    }
})
