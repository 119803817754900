import * as React from "react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import TiltAnimation from "../animations/TiltAnimation"

const WhatsAppLink = styled.a({
    backgroundColor: "#66BB6A",
    borderRadius: "0.8em",
    paddingTop: "0.6em",
    paddingBottom: "0.6em",
    paddingLeft: "1em",
    paddingRight: "1em",
    border: "2px solid #eee",
    position: "fixed",
    right: "1em",
    bottom: "10%",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.9)",
    textDecoration: "none",
    fontSize: "1.2em",
    transition: "font-size 300ms",
    "&:hover": {
        fontSize: "1.28em",
        "div:nth-of-type(1)": {
            animation: `${TiltAnimation} 400ms linear`
        }
    },
    "@media(max-width: 550px)": {
        borderRadius: "50%",
        paddingLeft: "0.6em",
        paddingRight: "0.6em",
    }
})

const WhatsAppText = styled.span({
    color: "#000",
    "@media(max-width: 550px)": {
        display: "none"
    }
})

const WhatsAppIconStyles = css({
    width: "1em",
    height: "1em",
    "@media(min-width: 550px)": {
        marginLeft: "0.7em",
    }
})

export default function WhatsAppIcon() {
    return (
        <WhatsAppLink href="https://wa.me/34644712329" rel="noreferrer noopener" target="_blank">
            <WhatsAppText>¡Contáctanos!</WhatsAppText>
            <StaticImage src="../assets/images/whatsapp.png" alt="WhatsApp" css={WhatsAppIconStyles} />
        </WhatsAppLink>
    )
}
