import * as React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ArrowStyles from "../styles/ArrowStyles"

const FooterContent = styled.footer({
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    backgroundColor: "rgba(20, 20, 20, 0.95)",
    backdropFilter: "blur(6px)",
    paddingBottom: "1em",
})

const LogoWrapper = styled.div({
    display: "flex",
    flexFlow: "column nowrap",
    flexBasis: "20%"
})

const LogoTitle = styled.p({
    textTransform: "uppercase",
    textAlign: "center",
    marginLeft: "0.8em",
    fontSize: "1.7em",
    color: "#eee",
    textShadow: "0 0 5px rgba(0, 0, 0, 0.7)",
    letterSpacing: "0.04em"
})

const LogoStyles = css({
    width: "9em",
    display: "block",
    margin: "0 auto",
    transition: "width 300ms, height",
    "&:hover": {
        width: "10em",
    }
})

const Navigation = styled.div({
    flexBasis: "20%",
    display: "flex",
    flexFlow: "column nowrap",
    color: "#eee",
    fontSize: "1.4em",
    marginTop: "1em",
    marginBottom: "0.8em"
})

const NavLink = styled(Link)({
    textDecoration: "none",
    color: "#eee",
    textTransform: "uppercase",
    letterSpacing: "0.04em",
    marginTop: "0.8em",
    fontSize: "0.75em",
    transition: "color 300ms",
    marginLeft: "1em",
    ...ArrowStyles("#eee"),
    "&:hover": {
        color: "#FBC02D",
        "&:before": {
            borderColor: "#FBC02D"
        }
    },
})

const FooterEndline = styled.div({
    backgroundColor: "rgba(240, 240, 240, 0.95)",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
    paddingRight: "30%",
    paddingLeft: "30%",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    "@media(max-width: 850px)": {
        paddingRight: "20%",
        paddingLeft: "20%",
    },
    "@media(max-width: 520px)": {
        paddingRight: "5%",
        paddingLeft: "5%",
    }
})

const AuthorLink = styled.a({
    color: "#000",
    textDecoration: "underline"
})

const MiscWrapper = styled.div({
    fontSize: "1.4em",
    flexBasis: "20%",
    display: "flex",
    flexFlow: "column nowrap",
    marginTop: "2.5em",
})

export default function Footer() {
    return (
        <>
            <FooterContent>
                <LogoWrapper>
                    <LogoTitle>Mayresur</LogoTitle>
                    <StaticImage src="../assets/images/logo.png" alt="Logo de Mayresur" css={LogoStyles} />
                </LogoWrapper>
                <Navigation>
                    Contenido
                    <NavLink to="/">Inicio</NavLink>
                    <NavLink to="/products">Productos</NavLink>
                    <NavLink to="/sectors">Sectores</NavLink>
                    <NavLink to="/contact">Contacto</NavLink>
                </Navigation>
                <MiscWrapper>
                    <NavLink to="/legal">Aviso legal</NavLink>
                    <NavLink to="/privacy">Política de privacidad</NavLink>
                    <NavLink to="/atributions">Atribuciones</NavLink>
                </MiscWrapper>
            </FooterContent>
            <FooterEndline>
                <span>&copy; Mayresur</span>
                <span>Made with ❤️ by <AuthorLink href="https://github.com/amg98" rel="noreferrer noopener" target="_blank">AMG</AuthorLink></span>
            </FooterEndline>
        </>
    )
}
