import { keyframes } from "@emotion/react"

export default keyframes`
    0%, 50%, 100% {
        transform: rotate(0deg)
    }
    25% {
        transform: rotate(25deg)
    }
    75% {
        transform: rotate(-25deg)
    }
`
